import React from "react";

import UnderConstruction from "./assets/undraw_under_construction.png";
import logo from "./assets/logo.png";

function App() {
  return (
    <div className="h-screen w-full max-w-7xl mx-auto text-[#6F6F6F]">
      <div className="flex flex-col items-center h-full justify-center p-5 gap-20">
        <img src={logo} alt="logo" />
        <img src={UnderConstruction} alt="under_construction" className="" />
        <h1 className="text-3xl font-bold">الموقع تحت الانشاء</h1>
      </div>
    </div>
  );
}

export default App;
